body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


/*the following is added by sgu*/

html,
body {
  height: 100%;
  margin: 0;
  line-height: 1.5;
  color: #121212;
}

#root {
  height: 100%;
  font-size: 30px;
}

.btn-link.mediaplayer-btn-link {
  /* same as #root */
  font-size: 30px; 
  vertical-align: baseline;
}

hr {
  border: none;
  height: 1px;
  color: #333; /* For older IE */
  background-color: #333; /* For modern browsers */
}

/* styles for md size screen */
@media (min-width: 768px) {
  .h-md-100 {
    height: 100%;
  }
}

#sidebar nav a:hover {
  background: #e3e3e3;
}

#sidebar nav a.active {
  background: hsl(224, 98%, 58%);
  color: white;
}

#sidebar nav a.pending {
  color: hsl(322, 98%, 58%);
}

#sidebar nav a.active.transitioning {
  color: green;
}

#sidebar nav ul {
  list-style-type: none;
}